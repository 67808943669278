import 'whatwg-fetch';
import {
    nextFriday, nextMonday, nextSaturday, nextSunday, nextThursday, nextTuesday, nextWednesday, previousFriday,
    previousMonday, previousSaturday,
    previousSunday,
    previousThursday,
    previousTuesday,
    previousWednesday,
    startOfDay
} from 'date-fns';

const ApiUrl = 'https://kaching.lesion.dk/api/';
const SiteUrl = 'https://kaching.lesion.dk/';

const Status = {
    ERROR: 0,
    SUCCESS: 1,
    FAIL: 2,
};

const Storage = {
    GetProfileKey: () =>{
        const localStore = localStorage.getItem('_kaching.profile');
        return localStore !== null ? localStore : null;
    },
    GetUserKey: () => {
        const localStore = localStorage.getItem('_kaching.user');
        return localStore !== null ? localStore : null;
    },
    GarbageCollector: () =>{
        const sessionStoreGarbage = sessionStorage.getItem('_kaching.profile');
        if(sessionStoreGarbage !== null){
            sessionStorage.removeItem('_kaching.profile');
        }
    }
}

const RandomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
}

const Wait = (seconds) =>{
    return new Promise(resolve => {
        setTimeout(resolve, seconds * 1000);
    });
}

const CreateDebounce = () =>{
    let timeout = null;
    return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fnc();
        }, delayMs || 500);
    };
}

const GenerateGuid = (number) =>{
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
    return `xx${number}xxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;
        if(d > 0){
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

const GetImageDataFromUri = (dataURI) => {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
}

const FormatAmount = (amount) => {
    return new Intl.NumberFormat('da-DK', { style: 'currency', currency: 'DKK' }).format(amount);
}

const FindTierByPoints = (tiers, points, lastTierAmount) =>{
    let returnValue = {};
    if(points >= lastTierAmount){
        returnValue = tiers[tiers.length - 1];
    } else{
        tiers.forEach((tier) =>{
            const splitTier = tier.tier.split('-');
            if(points >= parseInt(splitTier[0]) && points <= parseInt(splitTier[1])){
                returnValue = tier;
            }
        });
    }
    
    return returnValue;
}

const GetFallbackProfileImage = (profile) =>{
    let canvas = document.createElement('canvas');
    canvas.width = 200;
    canvas.height = 200;
    let ctx = canvas.getContext('2d');
    ctx.font = "80px sans-serif";
    ctx.textAlign = 'center';
    const approxFontHeight = parseInt(ctx.font) + 30;
    ctx.fillText(profile.name.charAt(0), 100, 100+(approxFontHeight/4));
    return canvas.toDataURL('image/webp');
}

const ApiRequest = (objBody) =>{
    return fetch( ApiUrl, {
        cache: 'no-store',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify( objBody )
    } ).catch(error => alert(error) );
}

const ValidateForm = (domForm) =>{
    let invalidInputs = [];
    Array.from( domForm.elements ).forEach( ( element ) => {
        const isValid = ValidateElement(element);
        if(isValid === false){
            invalidInputs.push(element);
        }
    } );
    return invalidInputs.length === 0;
}

const ValidateElement = (domElement) =>{
    const isValid = domElement.checkValidity();
    if(isValid){
        domElement.classList.remove( 'invalid' );
        domElement.classList.add( 'valid' );
        
    } else{
        domElement.classList.remove( 'valid' );
        domElement.classList.add( 'invalid' );
    }
    return isValid;
}

const UnregisterAndClearCaches = async () => {
    const registrations = await navigator.serviceWorker.getRegistrations();
    const unregisterPromises = registrations.map(registration => registration.unregister());

    const allCaches = await caches.keys();
    const cacheDeletionPromises = allCaches.map(cache => caches.delete(cache));

    await Promise.all([...unregisterPromises, ...cacheDeletionPromises]);
}

const GetWeekDayName = (dayIndex, dayName) => {
    const weekDays = ["Mandag", "Tirsdag","Onsdag","Torsdag","Fredag","Lørdag","Søndag"];
    if(dayName){
        return weekDays.findIndex( d => d.toLocaleLowerCase() === dayName) + 1;
    } else{
        return weekDays[dayIndex] || '';
    }
}

const GetCurrentPoints = (profile, startDayOfWeek) =>{
    let returnValue = 0;
    const currentWeekDate = new Date();
    const currentPaydayDates = GetPaydayDates(startDayOfWeek, currentWeekDate);
    profile.entries.forEach((entry) =>{
        const entryDate = new Date(entry.updated);
        if (startOfDay(entryDate) >= startOfDay(currentPaydayDates.prevStartDate) && startOfDay(entryDate) < startOfDay(currentPaydayDates.nextStartDate)) {
            if(!isNaN(entry.point)){
                returnValue += entry.point;
            }
        }
    })
    return returnValue;
}

const GetCurrentEntries = (profile, startDayOfWeek) =>{
    let returnValue = [];
    const currentWeekDate = new Date();
    const currentPaydayDates = GetPaydayDates(startDayOfWeek, currentWeekDate);
    profile.entries.forEach((entry) =>{
        const entryDate = new Date(entry.updated);
        if (startOfDay(entryDate) >= startOfDay(currentPaydayDates.prevStartDate) && startOfDay(entryDate) <= startOfDay(currentPaydayDates.nextStartDate)) {
            returnValue.push(entry);
        }
    })
    return returnValue;
}

const GetPayoutsForProfile = (profile, startDayOfWeek, tiers) => {
    let returnValue = {};
    const currentWeekDate = new Date();
    
    const lastWeekDate = new Date();
    lastWeekDate.setDate(lastWeekDate.getDate() - 7);
    const currentPaydayDates = GetPaydayDates(startDayOfWeek, currentWeekDate);
    const lastWeekPaydayDates = GetPaydayDates(startDayOfWeek, lastWeekDate);

    let currentWeekPoints = 0;
    let lastWeekPoints = 0;

    profile.entries.forEach((entry) =>{
        if(entry.updated){
            const entryDate = new Date(entry.updated);
            if (startOfDay(entryDate) >= startOfDay(currentPaydayDates.prevStartDate) && startOfDay(entryDate) < startOfDay(currentPaydayDates.nextStartDate)) {
                if(!isNaN(entry.point)){
                    currentWeekPoints += parseInt(entry.point);
                }
            } else if(startOfDay(entryDate) >= startOfDay(lastWeekPaydayDates.prevStartDate) && startOfDay(entryDate) < startOfDay(lastWeekPaydayDates.nextStartDate)){
                lastWeekPoints += parseInt(entry.point);
            }
        }
    });

    const lastTier = tiers[tiers.length - 1];
    const currentWeekTier = FindTierByPoints(tiers, currentWeekPoints, lastTier.amount);
    const lastWeekTier = FindTierByPoints(tiers, lastWeekPoints, lastTier.amount);
    returnValue.currentWeekTier = currentWeekTier;
    returnValue.lastWeekTier = lastWeekTier;
    return returnValue;
}

const GetPaydayDates = (startDayOfWeek, currentDate) => {
    let nextStartDate = currentDate;
    let prevStartDate = currentDate;
    
    
    switch ( startDayOfWeek ) {
        case 0:
            prevStartDate = previousSunday(currentDate);
            nextStartDate = nextSunday(currentDate);
            break;
        case 1:
            prevStartDate = previousMonday(currentDate);
            nextStartDate = nextMonday(currentDate);
            break;
        case 2:
            prevStartDate = previousTuesday(currentDate);
            nextStartDate = nextTuesday(currentDate);
            break;
        case 3:
            prevStartDate = previousWednesday(currentDate);
            nextStartDate = nextWednesday(currentDate);
            break;
        case 4:
            prevStartDate = previousThursday(currentDate);
            nextStartDate = nextThursday(currentDate);
            break;
        case 5:
            prevStartDate = previousFriday(currentDate);
            nextStartDate = nextFriday(currentDate);
            break;
        case 6:
            prevStartDate = previousSaturday(currentDate);
            nextStartDate = nextSaturday(currentDate);
            break;
    }

    if(startDayOfWeek === currentDate.getDay()){
        prevStartDate = currentDate;
    }

    return{
        prevStartDate,
        nextStartDate
    }
}


export { ApiUrl, SiteUrl, Status, Storage, CreateDebounce, RandomNumber, Wait, UnregisterAndClearCaches, ApiRequest, FormatAmount, ValidateForm, ValidateElement, GetImageDataFromUri, GetFallbackProfileImage, FindTierByPoints, GenerateGuid, GetWeekDayName, GetPaydayDates, GetPayoutsForProfile, GetCurrentPoints, GetCurrentEntries }