<template>
  <div :class="`modal ${requestPin ? 'is-active' : ''}`">
    <div class="modal-background" v-on:click="$emit('hasPincode', null)"></div>
    <div class="modal-content">
      <div class="content p-4 has-background-light">
        <h3 class="is-size-3 has-text-centered">Indtast pinkode</h3>
        <form ref="pincodeForm">
          <div class="pincode-container mb-4 is-flex is-justify-content-space-evenly">
            <div class="pincode-input" v-for="inputKey in inputs" :key="`pincode-input-${inputKey}`">
              <input inputmode="numeric" type="password" autocomplete="off" pattern="[0-9]{1}" maxlength="1" 
                     v-on:click="handleFocus(inputKey)"
                     v-on:input="handleInput($event, inputKey)"
                     v-on:keyup="handleKeyup($event, inputKey)"
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pincode',
  props:['requestPin'],
  data(){
    return {
      allowedSpecialKeys:[8],
      inputs:[0,1,2,3],
      pin:'',
    }
  },
  methods:{
    getInputs(){
      let container = document.querySelector('.pincode-container');
      return container.querySelectorAll('input');
    },
    handleFocus(key){
      if (key !== 0) {
        this.setFocus(0);
      }
    },
    setFocus(key){
      let inputs = this.getInputs();
      inputs[key].focus();
      inputs[key].setSelectionRange(0, 1);
    },
    reset(){
      let inputs = this.getInputs();
      inputs.forEach((input) =>{
        input.value = '';
        this.pin = '';
      });
    },
    handleKeyup(event, key){
      if(this.allowedSpecialKeys.includes(event.keyCode)){
        //Backspace
        if(event.keyCode === 8 && key > 0){
          this.setFocus(key - 1);
        }
      }
    },
    handleInput(event, key){
      let inputs = this.getInputs();
      
      if (!isNaN(event.target.value) && event.target.value.length === 1) {
        if (key === 3) {
          const userCode = [...inputs].map((input) => input.value).join('');
          document.activeElement.blur();
          this.reset();
          this.$emit('hasPincode', userCode);
        } else {
          inputs[key + 1].focus();
          inputs[key + 1].setSelectionRange(0, 1);
        }
        this.pin = [...inputs].map((input) => input.value).join('');
      }
    }
  },
  watch: {
    requestPin(isShow){
      if(isShow === true){
        let inputs = this.getInputs();
        setTimeout(() =>{
          inputs[0].focus();
        }, 0)
      }
    }
  },
};
</script>