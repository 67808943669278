<template>
  <div :class="`modal ${profile ? 'is-active' : ''}`">
    <div class="modal-background" v-on:click="this.$emit('closeProfileDetails')"></div>
    <div class="modal-card custom-modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <span v-if="view === 'details'">Indeværende periode</span>
          <span v-if="view === 'payout'">Udbetaling</span>
          </p>
        <button class="delete" aria-label="close" v-on:click="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <h2>{{ profile.name }}</h2>
          
          <div v-if="view === 'details'">
            <p class="is-flex is-justify-content-space-between"><strong>Optjent pulje : {{ formatAmount(getPayoutAmount) }}</strong><strong>Point: {{ getCurrentPoints(profile) }}</strong></p>
            <p class="mb-1"><strong>Opgaver:</strong></p>
            <ul class="mt-0 ml-0 is-text-small">
              <li class="is-flex is-justify-content-space-between border-bottom py-1" v-for="(task, key) in currentWeekEntries" :key="key">
                {{ task.label }}: <span class="tag">{{ task.count }}</span>
              </li>
            </ul>
          </div>
          <div v-if="view === 'payout'">
            <p class="is-flex is-justify-content-space-between mb-0 has-background-light p-1">
              Saldo: <span>{{ formatAmount( profile.balance ) }}</span>
            </p>
            <p class="is-flex is-justify-content-space-between mb-0 has-background-light p-1">
              Indeværende periode: <span>{{ formatAmount(getPayoutAmount) }}</span>
            </p>
            <p class="is-flex is-justify-content-space-between mb-0 has-background-grey-lighter mb-3 p-1">
              <strong>Total mulig udbetaling:</strong> <strong>{{ formatAmount(profile.balance + getPayoutAmount) }}</strong>
            </p>
            <div class="field has-addons">
              <div class="control is-expanded">
                <input class="input" type="number" placeholder="Udbetalingsværdi" v-model="payout" :max="profile.balance + getPayoutAmount" required v-on:input="setMax(profile.balance + getPayoutAmount)" :disabled="(profile.balance + getPayoutAmount) <= 0">
              </div>
              <div class="control">
                <a class="button is-info" v-on:click="makePayout" :disabled="(profile.balance + getPayoutAmount) <= 0">
                  Udbetal
                </a>
              </div>
            </div>
          </div>
          
          
        </div>
      </section>
     
    </div>
  </div>
</template>

<script>
import { GetCurrentPoints, GetCurrentEntries, GetPayoutsForProfile, FormatAmount, ApiRequest, Status } from '@/helpers';

export default {
  name: 'ProfileDetails',
  props:['profile', 'view'],
  data(){
    return {
      payout: 0
    }
  },
  computed:{
    user(){
      return this.$store.getters.user;
    },
    tasks(){
      return this.$store.getters.tasks
    },
    getPayoutAmount(){
      const startDayOfWeek = this.user.settings.payday;
      const tiers = this.$store.getters.tiers;
      const payouts = GetPayoutsForProfile(this.profile, startDayOfWeek, tiers);
      return payouts.currentWeekTier.amount === undefined ? 0 : payouts.currentWeekTier.amount;
    },
    currentWeekEntries(){
      let returnValue = {};
      const startDayOfWeek = this.user.settings.payday;
      const entries = GetCurrentEntries(this.profile, startDayOfWeek);
      entries.forEach((entry) =>{
        const task = this.tasks.find(t => t.id === entry.taskId);
        if(returnValue[task.id] === undefined){
          returnValue[task.id] = {
            label: task.label,
            count: 1
          };
        } else{
          returnValue[task.id].count++;
        }
      });
      return returnValue;
    }
  },
  methods:{
    setMax(maxAmount){
      if(this.payout > maxAmount){
        this.payout = maxAmount;
      }
    },
    formatAmount(amount){
      return FormatAmount(amount)
    },
    close(){
      this.$emit('closeProfileDetails');
    },
    getCurrentPoints(){
      const startDayOfWeek = this.user.settings.payday;
      return GetCurrentPoints(this.profile, startDayOfWeek);
    },
    makePayout(){
      if(this.payout > 0){
        ApiRequest( {
          controller: 'PROFILE',
          action: 'makePayout',
          profileId: this.profile.id,
          payout: this.payout
        } ).then( response => response.json() ).then((json) =>{
          this.payout = 0;
          if(json.status === Status.SUCCESS){
            this.$emit('closeProfileDetails');
          } else{
            console.error(json.statusMsg);
            this.$root.errorMsg = json.statusMsg;
          }
        });
      }
      
    }
  }
};
</script>

<style scoped>
.modal-content, .modal-card{
  width: auto;
}

</style>