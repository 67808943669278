<template>
  <nav class="navbar is-primary has-shadow is-sticky-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand m-0">
      <router-link to="/" class="navbar-item" v-if="showReturnButton">
        <font-awesome-icon icon="arrow-left"  />
      </router-link>
      <router-link  class="navbar-item " to="/">
       <div class="is-flex is-align-items-center mr-6">
         <img :src="logo" class="mr-1">
         <span>
           <span class="has-text-primary-light has-text-weight-light">Ka</span><span class="has-text-grey-light">|</span><span class="has-text-weight-bold is-italic">ching</span>
           <small class="navbar-version-no">
             v.{{ version }}
           </small>
         </span>
       </div>
      </router-link>
      <a role="button" :class="`navbar-burger ${showDropdown ? 'is-active' : ''}`" aria-label="menu" aria-expanded="false" v-on:click="toggleDropdown(!showDropdown)" v-if="isAuth">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>

      
    </div>

    <div id="mainNavigation" :class="`navbar-menu ${showDropdown ? 'is-active' : ''}`" ref="mainNavigation">
      <div class="navbar-start is-small">
        <router-link  class="navbar-item " to="/settings"  v-if="isAuth && hasProfile">
          <font-awesome-icon icon="gear" class="mr-1"  /> Indstillinger
        </router-link>
        <router-link class="navbar-item" v-if="isAuth && hasProfile" :to="{ name: 'EditProfile' }">
          <font-awesome-icon icon="user-edit" class="mr-1" /> Min profil
        </router-link>
        
        <hr class="navbar-divider" v-if="isAuth && hasProfile">
        
        <router-link  class="navbar-item" to="/overwatch"  v-if="isAuth && hasProfile && isAdmin">
          <font-awesome-icon icon="users-viewfinder" class="mr-1" /> Oversigt profiler
        </router-link>
        
        <router-link class="navbar-item" v-if="isAuth" :to="{ name: 'CreateProfile' }">
          <font-awesome-icon icon="user-plus" class="mr-1" /> Opret ny profil
        </router-link>

        

        
        <!--
        <hr class="navbar-divider" v-if="isAuth && hasProfile">
        <router-link class="navbar-item" v-if="isAuth && hasProfile" :to="{ name: 'Stats' }">
          <font-awesome-icon icon="chart-column" class="mr-1" /> Statestik
        </router-link>
        -->
        <hr class="navbar-divider">
        <a class="navbar-item" v-if="isAuth && hasProfile" v-on:click="unsetProfile">
          <font-awesome-icon icon="repeat" class="mr-1" /> Skift profil
        </a>
        <a class="navbar-item" v-on:click="logout" v-if="isAuth">
          <font-awesome-icon icon="arrow-right-from-bracket" class="mr-1" /> Log ud
        </a>
      </div>
    </div>

    <div class="navbar-end">
      <div class="navbar-item" v-if="hasProfile">
        <div class="tags has-addons">
          <span class="tag is-transparent">
            <img class="image profile-image is-clipped is-rounded is-bordered" :src="getProfileImage(profile)" :alt="profile.name">
          </span>
          <span class="tag is-transparent pl-0">{{ profile.name }}</span>
        </div>
      </div>
      <div class="navbar-item ml-auto" v-if="isAuth && hasProfile">
        <div class="tags has-addons">
          <span class="tag is-white">Point</span>
          <span class="tag is-success">{{ weeklyPoints }}</span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import logo from '../../public/logo.svg';

import { ApiRequest, GetFallbackProfileImage, GetCurrentPoints } from '@/helpers';
export default {
  name: 'Navbar',
  data(){
    return{
      logo: logo,
      routeChange: false,
      showDropdown: false
    }
  },
  computed:{
    version(){
      return this.$store.getters.version;
    },
    showReturnButton(){
      return this.$route.meta.buttons.indexOf('return') > -1;
    },
    user(){
      return this.$store.getters.user;
    },
    profile(){
      return this.$store.getters.profile; 
    },
    isAdmin(){
      return this.profile.isAdmin;
    },
    isAuth(){
      return this.$store.getters.isAuth;
    },
    hasProfile(){
      return this.$store.getters.hasProfile;
    },
    weeklyPoints() {
      return GetCurrentPoints(this.profile, this.user.settings.payday);
    }
  },
  methods:{
    toggleDropdown(payload) {
      if (this.showDropdown !== payload) this.routeChange = false;
      if (!this.routeChange) {
        this.showDropdown = payload;
      }
    },
    unsetProfile(){
      this.$store.commit('unsetProfile');
      const path = '/overview';
      if (this.$route.path !== path) {
        this.$router.push(path);
      } else{
        this.$forceUpdate();
      }
    },
    getProfileImage(profile){
      if(profile.image){
        return profile.image;
      } else{
        return GetFallbackProfileImage(profile);
      }
    },
    logout(){
      ApiRequest( {
        controller: 'AUTH',
        action: 'logout'
      } ).then( response => response.json() ).then(() =>{
        this.$store.commit('logout');
        const path = '/';
        if (this.$route.path !== path) {
          this.$router.push(path);
        } else{
          this.$forceUpdate();
        }
      });
    }
  },
  watch: {
    $route() {
      this.routeChange = true;
      this.showDropdown = false;
      this.$forceUpdate();
    }
  },
  mounted() {
    window.eventManager.listen( 'newProfileImage', () => {
      this.$forceUpdate();
    } );
    window.eventManager.listen( 'closeNav', () => {
      this.showDropdown = false;
    } );
  }
};
</script>


<style scoped>
.navbar-version-no{
  display: block;
  line-height: 0;
  opacity: .75;
  font-size: .5em;
  margin-top: 1px;
  margin-left: 1px;
}
</style>